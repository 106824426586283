import React, { useState } from 'react';
import { Column } from 'primereact/column';
import { CustomDataTable, filterApplyTemplate, filterClearTemplate, tableOptions } from '../components/CustomDatatable';
import NotifyController from '../utilities/Toast';
import FeedbackService from '../service/FeedbackService';
import { Image } from 'primereact/image';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'primereact/button';

const Report = () => {
    const navigate = useNavigate();
    const { page } = useParams();

    const [refresh, setRefresh] = useState<boolean>(false)


    const onOptionChange = async (option: tableOptions) => {
        const feedbacks = await FeedbackService.getInstance().getListFeedback(option.page, option.rowPerPage, 'APP_CHANGECLOTHER_POLICY').catch((error) => {
            NotifyController.error(error?.message)
            console.log(error);
        })
        return { total: feedbacks.data.totalRecord, data: feedbacks.data.items }
    }

    const bodyAssetUrl = (rowData: any) => {
        return (
            <div className="p-3">
                <Image src={rowData.image_url} alt={rowData.image_url} width="120" preview />
            </div>
        );
    };

    const bodyFormattedDateTime = (rowData: any) => {
        const timestamp = rowData.created_ts;
        const date = new Date(timestamp); // Convert the timestamp to a Date object
        date.setHours(date.getHours() + 7); // Adjust for GMT+7 timezone

        // Format the date as needed (e.g., 'yyyy-MM-dd HH:mm:ss' format)
        const formattedDateTime = date.toISOString().replace("T", " ").split(".")[0];

        return (
            <span>{formattedDateTime}</span>
        );
    };

    const handleDetailButtonClick = async (rowData: any) => {
        try {
            const imageData = await FeedbackService.getInstance().getIdFromUrl(rowData.image_url);
            navigate(`/generated-image/detail/${imageData.data}`);
        } catch (error: any) {
            NotifyController.error(error?.message);
            console.log(error);
        }
    };
    
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <h5>Feedback</h5>
                    <CustomDataTable
                        onOptionChange={onOptionChange}
                        refresh={refresh}
                        page={parseInt(page ?? '1')}
                    >
                        <Column
                            body={(rowData: any) => (
                                <Button onClick={() => handleDetailButtonClick(rowData)}>Detail</Button>
                            )}
                            header="Action"
                            headerStyle={{ width: '6rem', textAlign: 'center' }}
                            style={{ textAlign: 'center' }}
                        />
                        <Column field="created_ts" sortable body={bodyFormattedDateTime} header="Created" headerStyle={{ minWidth: '10rem' }} filter filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column>
                        <Column field="content" header="Content" sortable headerStyle={{ minWidth: '10rem' }} filter filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column>
                        <Column field="image_url" body={bodyAssetUrl} header="Asset" headerStyle={{ width: 120 }}></Column>
                    </CustomDataTable>

                </div>
            </div>
        </div>
    );
};

export default Report;
